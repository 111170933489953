<template>
	<main-container :showHeader="false">
		<el-card>
			<el-result v-if="hasErr" icon="error" title="错误提示" subTitle="网络错误,请稍后重试">
				<template slot="extra">
					<el-button type="primary" size="medium" @click="__init()">刷新</el-button>
				</template>
			</el-result>
			<template v-else>
				<advanced-search-bar>
					<template #default>
						<form-item-sites v-model="queryForm.sourceWebsite" ref="sites" :abbr="true" :local="true" :multiple="false" :parent="false" @onload="pageReady()" :clearable="false" @onerror="hasErr=true"></form-item-sites>
						<form-item label="Spu:">
							<el-input v-model="queryForm.spus" size="mini"></el-input>
						</form-item>
						<form-item>
							<el-button type="primary" @click="searchEvent" icon="el-icon-search" size="mini">查 询</el-button>
							<el-button type="primary" @click="exportData" size="mini">下 载</el-button>
						</form-item>
					</template>
					<template #advanced>
						<form-item label="目标站点:" class="w-100"  style="align-items: flex-start !important;">
							<div class="d-flex flex-column">
								<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" size="mini">ALL</el-checkbox>
								<el-checkbox-group v-model="queryForm.targetWebsites" @change="handleCheckedWebsiteChange" size="mini">
								    <el-checkbox v-for="item in targetWebsites" :label="item.key" :key="item.key">{{item.key}}</el-checkbox>
								</el-checkbox-group>
							</div>
						</form-item>
					</template>
				</advanced-search-bar>
				<divider />
				<el-table height="auto" :data="tableData" size="mini">
					<el-table-column label="站点" prop="sourceWebsite" min-width="150" align="center">
					</el-table-column>
					<el-table-column label="SPU" prop="spu" min-width="150" align="center">
					</el-table-column>
					<el-table-column label="目标站点" prop="targetWebsite" min-width="150" align="center">
					</el-table-column>
					<el-table-column label="目标站点SPU" prop="targetWebsiteSpu" min-width="150" align="center">
					</el-table-column>
					<el-table-column label="在架状态" prop="targetSpuStatus" min-width="150" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.targetSpuStatus !== undefined && scope.row.targetSpuStatus !== null">
								{{ scope.row.targetSpuStatus ? '在架' : '下架' }}
							</div>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
					:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
				</el-pagination>
			</template>
		</el-card>
	</main-container>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	import Page from '@/common/mixins/page.js'
	import {mapGetters} from 'vuex'
	export default {
		mixins: [Common, Page],
		inject: ['adminLayout'],
		data() {
			return {
				targetWebsites: [],
				queryForm: {
					sourceWebsite: '',
					spus: '',
					targetWebsites: []
				},
				hasErr: false,
				checkAll: false,
				isIndeterminate: false,
				tableData: [],
				namespace: 'lookup-spu',
				q: {},
				fetchOnCreated: false
			}
		},
		created() {
			// Init SPUS & Website
			const {spus, sourceWebsite} = this.$route.query
			this.queryForm.spus = spus || ''
			this.queryForm.sourceWebsite = sourceWebsite || ''
		},
		methods: {
			__init() {
				this.hasErr = false
				this.$nextTick(() => {
					this.$refs['sites'].__init()
				})
			},
			pageReady() {
				this.targetWebsites = this.$refs['sites'].websites
				this.queryForm.targetWebsites = this.targetWebsites.map(v => v.key)
				const {targetWebsites} = this.$route.query
				if (!!targetWebsites) {
					this.queryForm.targetWebsites = targetWebsites.split(',').filter(t => this.targetWebsites.find(w => w.key === t) !== undefined)
				}
				this.handleCheckedWebsiteChange(this.queryForm.targetWebsites)
				// QueryForm is Ready
				if (this.queryForm.sourceWebsite && this.queryForm.spus.match(/[\w-]+/g) && this.queryForm.targetWebsites.length > 0) {
					this.searchEvent()
				}
			},
			handleCheckAllChange(val) {
				this.queryForm.targetWebsites = val ? this.targetWebsites.map(v => v.key) : [];
				this.isIndeterminate = false
			},
			handleCheckedWebsiteChange(value) {
				let checkedCount = value.length
				this.checkAll = checkedCount === this.targetWebsites.length
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.targetWebsites.length
			},
			validateInputs() {
				const spus = this.queryForm.spus.match(/\d+/g) || []
				if (spus.length === 0) {
					return this.$showErrMsg('请输入您要查询的站点SPU')
				}
				if (this.queryForm.targetWebsites.length === 0) {
					return this.$showErrMsg('请选择需要查询的目标站点')
				}
				return {
					...this.queryForm,
					spus
				}
			},
			searchEvent() {
				const payload = this.validateInputs()
				if (payload === undefined) {
					return
				}
				this.q = payload
				this.page.current = 1
				this.getList()
				const search = Object.keys(this.q).map(key => {
					const value = this.q[key]
					if (Array.isArray(value)) {
						return `${key}=${value.join(',')}`
					}
					return `${key}=${value}`
				}).join('&')
				history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			},
			async getListUrl() {
				const payload = {
					...this.q,
					page: this.page.current,
					size: this.page.size
				}
				return this.$store.dispatch(`${this.namespace}/getList`, payload)
			},
			handleList(content) {
				this.tableData = content
			},
			exportData() {
				if (JSON.stringify(this.q) === '{}') return
				const payload = {
					action: `${this.namespace}/getList`,
					payload: {...this.q},
					title: 'SPU_MAPPING',
					dispatch: this.$store.dispatch,
					pagable: {page: 1, size: 50}
				}
				this.$store.commit('download-manager/addTask', payload)
			}
		}
	}
</script>

<style>
</style>
